/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.4.1/dist/jquery.min.js
 * - /npm/@popperjs/core@2.0.6/dist/umd/popper.min.js
 * - /npm/bootstrap@4.4.1/dist/js/bootstrap.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/jquery.scrollbar@0.2.11/jquery.scrollbar.min.js
 * - /npm/nouislider@14.1.1/distribute/nouislider.min.js
 * - /npm/wnumb@1.2.0/wNumb.min.js
 * - /npm/glightbox@2.0.5/dist/js/glightbox.min.js
 * - /npm/vue@2.7.16/dist/vue.js
 * - /npm/vue-router@3.1.6/dist/vue-router.min.js
 * - /npm/axios@0.19.2/dist/axios.min.js
 * - /npm/js-cookie@2.2.1/src/js.cookie.min.js
 * - /npm/vue-snotify@3.2.1/vue-snotify.min.js
 * - /npm/vue-directive-tooltip@1.6.3/dist/vueDirectiveTooltip.min.js
 * - /npm/lozad@1.14.0/dist/lozad.min.js
 * - /npm/vue-search-select@2.9.3/dist/VueSearchSelect.umd.min.js
 * - /npm/vue-the-mask@0.11.1/dist/vue-the-mask.min.js
 * - /npm/vue2-datepicker@3.7.0/index.min.js
 * - /npm/@fancyapps/ui@4.0.31/dist/fancybox.umd.js
 * - /npm/openseadragon@4.0.0/build/openseadragon/openseadragon.min.js
 * - /npm/resumablejs@1.1.0/resumable.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
